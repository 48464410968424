import React, { useState, useEffect } from 'react';
import supabase from '../config/supaBaseClient.js';
import '../css/AddQuatation.css';
import SearchableDropdown from './SearchDrobDown.js';
import PortPairs from './PortPairs'; // Dodajemo PortPairs komponentu
import { v4 as uuidv4 } from 'uuid';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';



function AddQuotation({ isSidebarOpen }) {
  const [formData, setFormData] = useState({
    quote_ref: uuidv4(),
    our_ref: '',
    import_type: '',
    handle_by: '',
    quote_valid_till: '',
    partner_code: '',
    partner_name: '',
    quote_currency: '',
  });

  const [errors, setErrors] = useState({});
  const [partnerList, setPartnerList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [isHeaderSaved, setIsHeaderSaved] = useState(false); // Kontrola statusa unosa header podataka
  const [currentStep, setCurrentStep] = useState(1); // Praćenje trenutnog koraka


  const importTypeOptions = ['Import', 'Export', 'Extrade', 'Others'];

  useEffect(() => {
    const fetchPartners = async () => {
      const { data, error } = await supabase.from('quote_partner').select('partner_name, partner_code');
      if (error) {
        console.error('Error fetching partners:', error.message);
      } else {
        setPartnerList(data);
      }
    };
   
    
    const fetchCurrencies = async () => {
      const { data, error } = await supabase.from('lib_currency').select('code');
      if (error) {
        console.error('Error fetching currencies:', error.message);
      } else {
        setCurrencyList(data.map((item) => item.code));
      }
    };

    fetchPartners();
    fetchCurrencies();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!formData.quote_ref) newErrors.quote_ref = 'Quote reference is required.';
    if (!formData.our_ref) newErrors.our_ref = 'Our reference is required.';
    if (!formData.import_type) newErrors.import_type = 'Import type is required.';
    if (!formData.handle_by) newErrors.handle_by = 'Handle by is required.';
    if (!formData.quote_valid_till) newErrors.quote_valid_till = 'Quote valid till is required.';
    if (!formData.partner_code) newErrors.partner_code = 'Partner code is required.';
    if (!formData.partner_name) newErrors.partner_name = 'Partner name is required.';
    if (!formData.quote_currency) newErrors.quote_currency = 'Quote currency is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async () => {
    if (!validateForm()) return;

    // Mapiranje za import_type
    const importTypeMap = {
      Import: 'I',
      Export: 'E',
      Extrade: 'X',
      Others: 'O',
    };

    const dataToInsert = {
      ...formData,
      import_type: importTypeMap[formData.import_type], // Postavlja slovo na osnovu izbora
      company: 'JGLS', // Fiksno
      entity_code: 'SGSIN', // Fiksno
      create_user: 'admin', // Fiksno
      update_user: 'admin', // Fiksno
      create_date: new Date().toISOString(), // Datum u ISO formatu
      update_date: new Date().toISOString(), // Datum u ISO formatu
      quote_status:'0'
    };

    try {
      const { error } = await supabase.from('quote_master').insert([dataToInsert]);
      setIsHeaderSaved(true);
      updateStep(2);

      if (error) {
        throw new Error(`Error creating quotation: ${error.message}`);
      }

      toast.success('Header save successfully!', {
        position: "top-right", // Pozicioniranje obaveštenja
        autoClose: 3000, // Automatsko zatvaranje nakon 3 sekunde
        hideProgressBar: false, // Prikazuje progres bar
        closeOnClick: true, // Zatvaranje na klik
        pauseOnHover: true, // Pauza ako se prelazi preko obaveštenja
        draggable: true, // Dozvoljava pomeranje
        progress: undefined, // Automatski stil progres bara
      });
      
      setIsHeaderSaved(true); // Postavljamo status na uspešno sačuvan header
    } catch (error) {
      alert(error.message);
    }
  };
 
  

  const updateStep = (step) => {
    setCurrentStep(step);
  };
  const handleRatesSave = () => {
   
    
    setCurrentStep(3); 
  };
  return (
    <div
    className={`add-quotation-container ${
      isSidebarOpen ? "sidebar-open" : "sidebar-closed"
    }`}
  >
  
      <h2>Add New Quotation</h2>
     
      <div className="progress-bar">
  {[...Array(3)].map((_, index) => {
    const isCompleted = currentStep > index + 1; // Završeni koraci
    const isActive = currentStep === index + 1; // Aktivni koraci

    return (
      <React.Fragment key={index}>
        <div
          className={`step ${isCompleted ? "completed" : ""} ${
            isActive ? "active" : ""
          }`}
        >
          {isCompleted ? <span>&#10003;</span> : ""}
        </div>
        {index < 2 && (
          <div
            className={`progress-line ${
              currentStep > index + 1 ? "fill" : ""
            }`}
          ></div>
        )}
      </React.Fragment>
    );
  })}
</div>



    <div className="box-container">
      <form>
        <div>
          <label>Quote Reference:</label>
          <input
            type="text"
            name="quote_ref"
            value={formData.quote_ref}
            onChange={handleChange}
            disabled={isHeaderSaved} // Onemogućujemo unos nakon čuvanja
          />
          {errors.quote_ref && <span className="error">{errors.quote_ref}</span>}
        </div>

        <div>
          <label>Our Reference:</label>
          <input
            type="text"
            name="our_ref"
            value={formData.our_ref}
            onChange={handleChange}
            disabled={isHeaderSaved}
          />
          {errors.our_ref && <span className="error">{errors.our_ref}</span>}
        </div>

        <div>
          <label>Import Type:</label>
          <select
            name="import_type"
            value={formData.import_type}
            onChange={handleChange}
            disabled={isHeaderSaved}
          >
            <option value="">Select Import Type</option>
            {importTypeOptions.map((type, index) => (
              <option key={index} value={type}>
                {type}
              </option>
            ))}
          </select>
          {errors.import_type && <span className="error">{errors.import_type}</span>}
        </div>

        <div>
          <label>Handle By:</label>
          <input
            type="text"
            name="handle_by"
            value={formData.handle_by}
            onChange={handleChange}
            disabled={isHeaderSaved}
          />
          {errors.handle_by && <span className="error">{errors.handle_by}</span>}
        </div>

        <div>
          <label>Quote Valid Till:</label>
          <input
            type="date"
            name="quote_valid_till"
            value={formData.quote_valid_till}
            onChange={handleChange}
            disabled={isHeaderSaved}
          />
          {errors.quote_valid_till && <span className="error">{errors.quote_valid_till}</span>}
        </div>

        <div>
          <label>Partner Name:</label>
          <SearchableDropdown
            options={partnerList.map((partner) => partner.partner_name)}
            selectedValue={formData.partner_name}
            onChange={(value) => {
              const selectedPartner = partnerList.find((partner) => partner.partner_name === value);
              setFormData({
                ...formData,
                partner_name: value,
                partner_code: selectedPartner ? selectedPartner.partner_code : '',
              });
            }}
            placeholder="Search Partner Name"
            disabled={isHeaderSaved}
          />
          {errors.partner_name && <span className="error">{errors.partner_name}</span>}
        </div>

        <div>
          <label>Partner Code:</label>
          <input
            type="text"
            name="partner_code"
            value={formData.partner_code}
            readOnly
          />
          {errors.partner_code && <span className="error">{errors.partner_code}</span>}
        </div>

        <div>
          <label>Quote Currency:</label>
          <SearchableDropdown
            options={currencyList}
            selectedValue={formData.quote_currency}
            onChange={(value) =>
              setFormData({ ...formData, quote_currency: value })
            }
            placeholder="Search Currency"
            disabled={isHeaderSaved}
          />
          {errors.quote_currency && <span className="error">{errors.quote_currency}</span>}
        </div>

        {!isHeaderSaved && (
          <button type="button" onClick={handleSubmit}>
            Save Quotation
          </button>
        )}
      </form>
      </div>
      <div className="box-container">
      {isHeaderSaved && (
        <div className="port-pairs-section">
          <h3>Port Pairs</h3>
          <PortPairs quoteRef={formData.quote_ref} onRatesSave={handleRatesSave}   onSave={() => {
            toast.success('Port Pairs saved successfully!', {
            position: "top-right", 
            autoClose: 3000, 
            hideProgressBar: false, 
            closeOnClick: true,
            pauseOnHover: true, 
            draggable: true,
            progress: undefined, 
          });
          
           
          }}/>
        </div>

      )}
      </div>
    </div>
  );
}

export default AddQuotation;
