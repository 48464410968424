import React, { useState, useEffect } from 'react';
import supabase from '../config/supaBaseClient';
import AddFcl from './AddFcl';
import AddLcl from './AddLcl';
import AddAir from './AddAir';
import { v4 as uuidv4 } from 'uuid';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function PortPairs({ quoteRef, onRatesSave }) {
  const navigate = useNavigate();


  const [portPairs, setPortPairs] = useState([]);
  const [currentPortPair, setCurrentPortPair] = useState(null);
  const [portPairData, setPortPairData] = useState({
    from_port: '',
    from_port_name: '',
    to_port: '',
    to_port_name: '',
    cargo_type: '',
    job_type: '',
    incoterm: '',
    port_notes: '',
  });
  const [jobTypeList, setJobTypeList] = useState([]);
  const [incotermList, setIncotermList] = useState([]);
  const [errors, setErrors] = useState({});
  const [isSaved, setIsSaved] = useState(false);
  const [currentStep, setCurrentStep] = useState(2); // Praćenje trenutnog koraka

  useEffect(() => {
    const fetchJobTypes = async () => {
      const { data, error } = await supabase.from('job_type').select('job_type, job_type_desc');
      if (error) {
        console.error('Error fetching job types:', error.message);
      } else {
        setJobTypeList(data || []);
      }
    };

    const fetchIncoterms = async () => {
      const { data, error } = await supabase.from('incoterm').select('incoterm, incoterm_desc');
      if (error) {
        console.error('Error fetching incoterms:', error.message);
      } else {
        setIncotermList(data || []);
      }
    };

    fetchJobTypes();
    fetchIncoterms();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setPortPairData({ ...portPairData, [name]: value });
  };

  const validateForm = () => {
    const newErrors = {};
    if (!portPairData.from_port) newErrors.from_port = 'From Port is required.';
    if (!portPairData.to_port) newErrors.to_port = 'To Port is required.';
    if (!portPairData.cargo_type) newErrors.cargo_type = 'Cargo Type is required.';
    if (!portPairData.job_type) newErrors.job_type = 'Job Type is required.';
    if (!portPairData.incoterm) newErrors.incoterm = 'Incoterm is required.';
    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSave = async () => {
    if (!validateForm()) return;

    const dataToInsert = {
      ...portPairData,
      port_ref: uuidv4(),
      quote_ref: quoteRef,
      company: 'JGLS',
      entity_code: 'SGSIN',
      create_user: 'admin',
      update_user: 'admin',
      create_date: new Date().toISOString(),
      update_date: new Date().toISOString(),
    };

    try {
      const { error } = await supabase.from('quote_port_pair').insert([dataToInsert]);

      if (error) {
        throw new Error(`Error saving Port Pair: ${error.message}`);
      }

      toast.success('Port Pairs saved successfully!', {
        position: "top-right", // Pozicioniranje obaveštenja
        autoClose: 3000, // Automatsko zatvaranje nakon 3 sekunde
        hideProgressBar: false, // Prikazuje progres bar
        closeOnClick: true, // Zatvaranje na klik
        pauseOnHover: true, // Pauza ako se prelazi preko obaveštenja
        draggable: true, // Dozvoljava pomeranje
        progress: undefined, // Automatski stil progres bara
      });
    
      setPortPairs((prev) => [...prev, dataToInsert]);
      setCurrentPortPair(dataToInsert);
      setIsSaved(true);
      if (onRatesSave) {
        onRatesSave(); // Pozivamo funkciju iz roditeljske komponente
      }
    } catch (error) {
      alert(error.message);
    }
  };
  const handleSaveRates = () => {
  
    console.log('Rates saved for quoteRef:', quoteRef);

   
  };
  return (
    <div className="port-pairs-container">
      <h2>Port Pairs</h2>
      <form>
        <div>
          <label>From Port:</label>
          <input
            type="text"
            name="from_port"
            value={portPairData.from_port}
            placeholder="Enter From Port Code (e.g., NYK)"
            maxLength={5}
            readOnly={isSaved}
            onChange={handleChange}
          />
          {errors.from_port && <span className="error">{errors.from_port}</span>}
        </div>
        <div>
          <label>From Port Name:</label>
          <input
            type="text"
            name="from_port_name"
            value={portPairData.from_port_name}
            placeholder="Enter From Port Name (e.g., New York)"
            maxLength={100}
            readOnly={isSaved}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>To Port:</label>
          <input
            type="text"
            name="to_port"
            value={portPairData.to_port}
            placeholder="Enter To Port Code (e.g., SFO)"
            maxLength={5}
            readOnly={isSaved}
            onChange={handleChange}
          />
          {errors.to_port && <span className="error">{errors.to_port}</span>}
        </div>
        <div>
          <label>To Port Name:</label>
          <input
            type="text"
            name="to_port_name"
            value={portPairData.to_port_name}
            placeholder="Enter To Port Name (e.g., San Francisco)"
            maxLength={100}
            readOnly={isSaved}
            onChange={handleChange}
          />
        </div>
        <div>
          <label>Cargo Type:</label>
          <select
            name="cargo_type"
            value={portPairData.cargo_type}
            disabled={isSaved}
            onChange={handleChange}
          >
            <option value="">Select Cargo Type</option>
            <option value="FCL">FCL</option>
            <option value="LCL">LCL</option>
            <option value="AIR">AIR</option>
          </select>
        </div>
        <div>
          <label>Job Type:</label>
          <select
            name="job_type"
            value={portPairData.job_type}
            disabled={isSaved}
            onChange={handleChange}
          >
            <option value="">Select Job Type</option>
            {jobTypeList.map((job) => (
              <option key={job.job_type} value={job.job_type}>
                {job.job_type_desc || job.job_type}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Incoterm:</label>
          <select
            name="incoterm"
            value={portPairData.incoterm}
            disabled={isSaved}
            onChange={handleChange}
          >
            <option value="">Select Incoterm</option>
            {incotermList.map((term) => (
              <option key={term.incoterm} value={term.incoterm}>
                {term.incoterm_desc || term.incoterm}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label>Port Notes:</label>
          <textarea
            name="port_notes"
            value={portPairData.port_notes}
            placeholder="Enter additional notes about the port"
            maxLength={255}
            readOnly={isSaved}
            onChange={handleChange}
          />
        </div>
        {!isSaved && (
          <button type="button" onClick={handleSave}     onSave={() => {
           
            setCurrentStep(2); // Prelazak na Step 2
          }} >
            Save Port Pair
          </button>
        )}
      </form>

      {isSaved && (
  <div className="add-buttons">
    <h2>Add Rates</h2>
    <div>
      <h4>FCL Rates</h4>
      <AddFcl portRef={currentPortPair.port_ref} quoteRef={quoteRef} />
    </div>
    <div>
      <h4>LCL Rates</h4>
      <AddLcl portRef={currentPortPair.port_ref} quoteRef={quoteRef} />
    </div>
    <div>
      <h4>AIR Rates</h4>
      <AddAir portRef={currentPortPair.port_ref} quoteRef={quoteRef} />
    </div>

    {/* Dodato dugme za završetak */}
    <button
      className="finish-btn"
      onClick={() => {
        Swal.fire({
          icon: 'success',
          title: 'Quotation Generated',
          text: 'Quotation has been successfully generated!',
        }).then(() => {
          navigate('/quotations'); // Navigacija na /quotation
        });
      }}
    >
      DONE
    </button>
  </div>
)}




    </div>
  );
}

export default PortPairs;
