import React, { useState, useEffect } from 'react';
import { FaSave, FaTrash, FaPlus, FaFileExport, FaCheckCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import supabase from '../../config/supaBaseClient';
import '../../css/RateManagement/RateFcl_m.css';
import '../../css/RateManagement/RateAir_m.css';
import Papa from 'papaparse';

function RateAir_m({ isSidebarOpen }) {
  const [rates, setRates] = useState([]);
  const [filteredRates, setFilteredRates] = useState([]); // Za filtriranje i pretragu
  const [loading, setLoading] = useState(false); // Indikator za loading
  const [selectedRates, setSelectedRates] = useState([]); // Selektovani redovi
  
  const [filters, setFilters] = useState({
    validTillDate: '',
    carrierSearch: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  useEffect(() => {
    const filtered = rates.filter((rate) =>
      Object.values(rate).some((value) =>
        String(value).toLowerCase().includes(search.toLowerCase())
      )
    );
    setFilteredRates(filtered);
  }, [search, rates]);
  const fetchFilteredRates = async () => {
    try {
      setLoading(true); // Prikazuje loader dok traje pretraga
  
      let query = supabase.from('rates_air').select('*');
  
      // Primena filtera samo ako su vrednosti dostupne
      if (filters.validTillDate) {
        query = query.eq('valid_to', filters.validTillDate);
      }
      if (filters.carrierSearch) {
        query = query.ilike('carrier_code', `%${filters.carrierSearch}%`);
      }
      if (filters.currencySearch) {
        query = query.ilike('currency_code', `%${filters.currencySearch}%`);
      }
      if (filters.minRate) {
        query = query.gte('min_rate', filters.minRate); // >= za minimalnu stopu
      }
  
      // Paginate results
      query = query.range(
        (currentPage - 1) * rowsPerPage,
        currentPage * rowsPerPage - 1
      );
  
      const { data, error } = await query;
  
      if (error) throw new Error(error.message);
  
      setRates(data); // Postavlja rezultate
      setFilteredRates(data); // Postavlja filtrirane rezultate
    } catch (error) {
      Swal.fire('Error', error.message, 'error');
    } finally {
      setLoading(false); // Uklanja loader
    }
  };
  
  
  const handleAddRate = () => {
    const newRate = {
      company: 'Default Company',
      entity_code: 'Default Entity',
      itemno: '',
      itemdesc: '',
      carrier_code: '',
      valid_from: new Date().toISOString().split('T')[0],
      valid_to: '',
      currency_code: '',
      min_rate: 0,
      lesser_45_rate: 0,
      greater_45_rate: 0,
      greater_100_rate: 0,
      greater_300_rate: 0,
      greater_500_rate: 0,
      greater_1000_rate: 0,
      rate_notes: '',
      create_date: new Date().toISOString(),
      create_user: 'admin',
      update_date: new Date().toISOString(),
      update_user: 'admin',
    };
  
    setRates((prev) => [...prev, newRate]);
  };
  
  const handleSave = async (index) => {
    try {
      const rateToSave = { ...rates[index] };
  
      if (
        !rateToSave.valid_to ||
        !rateToSave.carrier_code ||
        !rateToSave.itemno ||
        !rateToSave.currency_code
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Validation Error',
          text: 'Please fill in all required fields.',
        });
        return;
      }
  
      const payload = {
        company: rateToSave.company,
        entity_code: rateToSave.entity_code,
        itemno: rateToSave.itemno,
        itemdesc: rateToSave.itemdesc,
        carrier_code: rateToSave.carrier_code,
        valid_from: rateToSave.valid_from,
        valid_to: rateToSave.valid_to,
        currency_code: rateToSave.currency_code,
        min_rate: rateToSave.min_rate,
        lesser_45_rate: rateToSave.lesser_45_rate,
        greater_45_rate: rateToSave.greater_45_rate,
        greater_100_rate: rateToSave.greater_100_rate,
        greater_300_rate: rateToSave.greater_300_rate,
        greater_500_rate: rateToSave.greater_500_rate,
        greater_1000_rate: rateToSave.greater_1000_rate,
        rate_notes: rateToSave.rate_notes,
        create_date: rateToSave.create_date || new Date().toISOString(),
        create_user: rateToSave.create_user || 'admin',
        update_date: new Date().toISOString(),
        update_user: 'admin',
      };
  
      if (rateToSave.rate_seq) {
        const { error } = await supabase
          .from('rates_air')
          .update(payload)
          .eq('rate_seq', rateToSave.rate_seq);
  
        if (error) throw new Error(`Error updating rate: ${error.message}`);
        Swal.fire('Success', 'Rate updated successfully!', 'success');
      } else {
        const { error: insertError } = await supabase.from('rates_air').insert([payload]);
  
        if (insertError) throw new Error(`Error inserting rate: ${insertError.message}`);
  
        const { data: insertedRate } = await supabase
          .from('rates_air')
          .select('*')
          .order('rate_seq', { ascending: false })
          .limit(1);
  
        if (insertedRate && insertedRate.length > 0) {
          setRates((prev) => {
            const updatedRates = [...prev];
            updatedRates[index] = { ...payload, rate_seq: insertedRate[0].rate_seq };
            return updatedRates;
          });
          Swal.fire('Success', 'New rate added successfully!', 'success');
        }
      }
    } catch (error) {
      Swal.fire('Error', error.message, 'error');
    }
  };
  
  const handleImportCSV = async (event) => {
    const file = event.target.files[0];
  
    if (!file) {
      Swal.fire('Error', 'No file selected.', 'error');
      return;
    }
  
    if (!file.name.endsWith('.csv')) {
      Swal.fire('Error', 'Please upload a valid CSV file.', 'error');
      return;
    }
  
    setLoading(true);
  
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result) => {
        setLoading(false);
  
        if (result.errors.length > 0) {
          Swal.fire('Error', 'Error parsing the file.', 'error');
          console.error(result.errors);
          return;
        }
  
        const importedData = result.data.map((row) => ({
          ...row,
          create_date: new Date().toISOString(),
          create_user: 'admin',
          update_date: new Date().toISOString(),
          update_user: 'admin',
        }));
  
        try {
          const { error } = await supabase.from('rates_air').insert(importedData);
  
          if (error) {
            throw new Error(`Error saving imported data: ${error.message}`);
          }
  
          setRates((prevRates) => [...prevRates, ...importedData]);
          Swal.fire('Success', 'Data imported successfully!', 'success');
        } catch (dbError) {
          Swal.fire('Error', dbError.message, 'error');
          console.error(dbError);
        }
      },
      error: (parseError) => {
        setLoading(false);
        Swal.fire('Error', 'Error reading the file.', 'error');
        console.error(parseError);
      },
    });
  };
  
  const handleExportCSV = () => {
    if (filteredRates.length === 0) {
      Swal.fire('Error', 'No data available to export.', 'error');
      return;
    }
  
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [
        Object.keys(filteredRates[0]).join(','), // Zaglavlje (nazivi kolona)
        ...filteredRates.map((row) =>
          Object.values(row)
            .map((value) => `"${value}"`) // Escape za sigurnost
            .join(',')
        ),
      ].join('\n');
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'rates_air.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  
  const handleExportJSON = () => {
    if (filteredRates.length === 0) {
      Swal.fire('Error', 'No data available to export.', 'error');
      return;
    }
  
    const jsonContent = JSON.stringify(filteredRates, null, 2);
    const blob = new Blob([jsonContent], { type: 'application/json' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'rates_air.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
          
  const handleDelete = async (rateSeq, index) => {
    try {
      if (!rateSeq) {
        Swal.fire('Error', 'Cannot delete rate without a valid rate_seq.', 'error');
        return;
      }
  
      const { error } = await supabase.from('rates_air').delete().eq('rate_seq', rateSeq);
  
      if (error) throw new Error(error.message);
  
      setRates((prev) => prev.filter((_, i) => i !== index));
      Swal.fire('Success', 'Rate deleted successfully!', 'success');
    } catch (error) {
      Swal.fire('Error', error.message, 'error');
    }
  };
  const sortRates = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
  
    const sortedRates = [...filteredRates].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  
    setFilteredRates(sortedRates);
    setSortConfig({ key, direction });
  };
  const handleInputChange = (index, field, value) => {
    setRates((prevRates) => {
      const updatedRates = [...prevRates];
      updatedRates[index][field] = value;
      return updatedRates;
    });
  };

  const handlePageChange = (direction) => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    } else if (direction === 'next' && currentPage * rowsPerPage < filteredRates.length) {
      setCurrentPage((prev) => prev + 1);
    }
  };
  
  const handleRowsPerPageChange = (e) => {
    setRowsPerPage(Number(e.target.value));
    setCurrentPage(1); // Resetuje na prvu stranicu
  };
  const paginatedRates = filteredRates.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );
    
  
  return (
    <div className={`rates-table-container ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <h3>Rates Management - Air</h3>
     
      <div className="table-controls">
  <button onClick={handleAddRate} className="btn-add">
    <FaPlus /> Add New Rate
  </button>

  <div className="dropdown import-dropdown">
    <button className="dropdown-toggle">Import</button>
    <ul className="dropdown-menu">
      <li>
        <label htmlFor="csv-upload" style={{ cursor: 'pointer' }}>
          Import CSV
        </label>
        <input
          id="csv-upload"
          type="file"
          accept=".csv"
          onChange={handleImportCSV}
          style={{ display: 'none' }}
        />
      </li>
      <li>Copy to Quote</li>
      <li>Import to Quote</li>
    </ul>
  </div>

  <div className="dropdown export-dropdown">
    <button className="dropdown-toggle">Export</button>
    <ul className="dropdown-menu">
      <li onClick={handleExportCSV}>Export as CSV</li>
      <li onClick={handleExportJSON}>Export as JSON</li>
    </ul>
  </div>
</div>
      {/* Filteri, tabela, i akcije će biti dodati kasnije */}
      <div className="filters-container">
  <input
    type="date"
    placeholder="Valid Till Date"
    value={filters.validTillDate}
    onChange={(e) => setFilters({ ...filters, validTillDate: e.target.value })}
    className="filter-input"
  />
  <input
    type="text"
    placeholder="Carrier Code"
    value={filters.carrierSearch}
    onChange={(e) => setFilters({ ...filters, carrierSearch: e.target.value })}
    className="filter-input"
  />
  <input
    type="text"
    placeholder="Currency Code"
    value={filters.currencySearch}
    onChange={(e) => setFilters({ ...filters, currencySearch: e.target.value })}
    className="filter-input"
  />
  <input
    type="number"
    placeholder="Min Rate"
    value={filters.minRate}
    onChange={(e) => setFilters({ ...filters, minRate: e.target.value })}
    className="filter-input"
  />
  <button onClick={fetchFilteredRates} className="filter-btn">
    Search
  </button>
</div>

{loading ? (
  <div className="loading-container">
    <div className="spinner"></div>
    <p>Loading... Please wait</p>
  </div>
) : (
 <>
 <div className="results-and-search">
  <div className="results-counter">
    {filteredRates.length > 0 ? (
      <div className="results-box">
        <span className="results-icon">🔍</span>
        <span className="results-text">
          Found <strong>{filteredRates.length}</strong> result(s)
        </span>
      </div>
    ) : (
      <div className="results-box no-results">
        <span className="results-icon">😞</span>
        <span className="results-text">No results found</span>
      </div>
    )}
  </div>
</div>


<div className="search-pagination-wrapper">
  <div className="search-container">
    <div className="search-wrapper">
      <span className="search-icon">🔍</span>
      <input
        type="text"
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="search-input"
      />
    </div>
  </div>
  <div className="pagination-container">
    <button
      onClick={() => handlePageChange('prev')}
      disabled={currentPage === 1}
      className="pagination-btn"
    >
      Prev
    </button>
    <span className="pagination-info">
      Page {currentPage} of {Math.ceil(filteredRates.length / rowsPerPage)}
    </span>
    <button
      onClick={() => handlePageChange('next')}
      disabled={currentPage === Math.ceil(filteredRates.length / rowsPerPage)}
      className="pagination-btn"
    >
      Next
    </button>
    <select
      value={rowsPerPage}
      onChange={(e) => setRowsPerPage(Number(e.target.value))}
      className="pagination-select"
    >
      <option value={5}>5</option>
      <option value={10}>10</option>
      <option value={20}>20</option>
    </select>
  </div>
  </div>
  <table className="rates-table">
  <thead>
  <tr>
    <th>
      <input
        type="checkbox"
        checked={selectedRates.length === filteredRates.length}
        onChange={() =>
          setSelectedRates(
            selectedRates.length === filteredRates.length
              ? []
              : filteredRates.map((rate) => rate.rate_seq)
          )
        }
      />
    </th>
    <th onClick={() => sortRates('itemno')}>
      Item No {sortConfig.key === 'itemno' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('itemdesc')}>
      Description {sortConfig.key === 'itemdesc' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('carrier_code')}>
      Carrier Code {sortConfig.key === 'carrier_code' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('valid_from')}>
      Valid From {sortConfig.key === 'valid_from' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('valid_to')}>
      Valid To {sortConfig.key === 'valid_to' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('currency_code')}>
      Currency Code {sortConfig.key === 'currency_code' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('min_rate')} style={{ textAlign: 'center' }}>
      Min Rate {sortConfig.key === 'min_rate' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('lesser_45_rate')} style={{ textAlign: 'center' }}>
      &lt; 45 Rate {sortConfig.key === 'lesser_45_rate' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('greater_45_rate')} style={{ textAlign: 'center' }}>
      &gt; 45 Rate {sortConfig.key === 'greater_45_rate' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('greater_100_rate')} style={{ textAlign: 'center' }}>
      &gt; 100 Rate {sortConfig.key === 'greater_100_rate' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('greater_300_rate')} style={{ textAlign: 'center' }}>
      &gt; 300 Rate {sortConfig.key === 'greater_300_rate' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('greater_500_rate')} style={{ textAlign: 'center' }}>
      &gt; 500 Rate {sortConfig.key === 'greater_500_rate' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('greater_1000_rate')} style={{ textAlign: 'center' }}>
      &gt; 1000 Rate {sortConfig.key === 'greater_1000_rate' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th>Notes</th>
    <th>Actions</th>
  </tr>
</thead>

  <tbody>
    {filteredRates.map((rate, index) => (
      <tr key={`rate-air-${index}`}>
        <td>
        <div className="checkbox-wrapper">
          <input
            type="checkbox"
            id={`select-${index}`}
            checked={selectedRates.includes(rate.rate_seq)}
            onChange={() =>
              setSelectedRates((prev) =>
                prev.includes(rate.rate_seq)
                  ? prev.filter((id) => id !== rate.rate_seq)
                  : [...prev, rate.rate_seq]
              )
            }
          />
          <label htmlFor={`select-${index}`} className="checkbox-label">
            {selectedRates.includes(rate.rate_seq) && <FaCheckCircle className="check-icon" />}
          </label>
        </div>
      </td>

        {/* Data Columns */}
        <td>
          <input
            type="text"
            value={rate.itemno || ''}
            onChange={(e) => handleInputChange(index, 'itemno', e.target.value)}
          />
        </td>
        <td>
          <input
            type="text"
            value={rate.itemdesc || ''}
            onChange={(e) => handleInputChange(index, 'itemdesc', e.target.value)}
          />
        </td>
        <td>
          <input
            type="text"
            value={rate.carrier_code || ''}
            onChange={(e) => handleInputChange(index, 'carrier_code', e.target.value)}
          />
        </td>
        <td>
          <input
            type="date"
            value={rate.valid_from || ''}
            onChange={(e) => handleInputChange(index, 'valid_from', e.target.value)}
          />
        </td>
        <td>
          <input
            type="date"
            value={rate.valid_to || ''}
            onChange={(e) => handleInputChange(index, 'valid_to', e.target.value)}
          />
        </td>
        <td>
          <input
            type="text"
            value={rate.currency_code || ''}
            onChange={(e) => handleInputChange(index, 'currency_code', e.target.value)}
          />
        </td>
        <td>
          <input
            type="number"
            value={rate.min_rate || ''}
            onChange={(e) => handleInputChange(index, 'min_rate', e.target.value)}
            style={{ textAlign: 'center' }}
          />
        </td>
        <td>
          <input
            type="number"
            value={rate.lesser_45_rate || ''}
            onChange={(e) => handleInputChange(index, 'lesser_45_rate', e.target.value)}
            style={{ textAlign: 'center' }}
          />
        </td>
        <td>
          <input
            type="number"
            value={rate.greater_45_rate || ''}
            onChange={(e) => handleInputChange(index, 'greater_45_rate', e.target.value)}
            style={{ textAlign: 'center' }}
          />
        </td>
        <td>
          <input
            type="number"
            value={rate.greater_100_rate || ''}
            onChange={(e) => handleInputChange(index, 'greater_100_rate', e.target.value)}
            style={{ textAlign: 'center' }}
          />
        </td>
        <td>
          <input
            type="number"
            value={rate.greater_300_rate || ''}
            onChange={(e) => handleInputChange(index, 'greater_300_rate', e.target.value)}
            style={{ textAlign: 'center' }}
          />
        </td>
        <td>
          <input
            type="number"
            value={rate.greater_500_rate || ''}
            onChange={(e) => handleInputChange(index, 'greater_500_rate', e.target.value)}
            style={{ textAlign: 'center' }}
          />
        </td>
        <td>
          <input
            type="number"
            value={rate.greater_1000_rate || ''}
            onChange={(e) => handleInputChange(index, 'greater_1000_rate', e.target.value)}
            style={{ textAlign: 'center' }}
          />
        </td>

        {/* Notes Column */}
        <td>
          <textarea
            value={rate.rate_notes || ''}
            onChange={(e) => handleInputChange(index, 'rate_notes', e.target.value)}
            style={{
              width: '100%',
              boxSizing: 'border-box',
              resize: 'none',
              minHeight: '40px',
            }}
          />
        </td>

        {/* Actions Column */}
        <td>
          <div className="action-buttons">
            <button onClick={() => handleSave(index)} className="save-btn">
              <FaSave />
            </button>
            <button onClick={() => handleDelete(rate.rate_seq, index)} className="delete-btn">
              <FaTrash />
            </button>
          </div>
        </td>
      </tr>
    ))}
  </tbody>
</table>

</>
)}
    </div>
  );
}

export default RateAir_m;
