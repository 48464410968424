import React from 'react';

function Logs() {
  return (
    <div>
      <h2>Logs</h2>
      {/* Sadržaj za Logs */}
    </div>
  );
}

export default Logs;
