import React, { useState, useRef } from 'react';

function SearchableDropdown({ options, selectedValue, onChange, placeholder }) {
  const [searchTerm, setSearchTerm] = useState('');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const dropdownRef = useRef();

  // Filtriranje opcija prema pretrazi
  const filteredOptions = options.filter((option) =>
    option.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSelect = (value) => {
    onChange(value); // Postavi izabranu vrednost
    setSearchTerm(''); // Resetuj polje za unos
    setIsDropdownOpen(false); // Zatvori dropdown
  };

  const handleInputChange = (e) => {
    setSearchTerm(e.target.value);
    setIsDropdownOpen(true); // Otvori dropdown kada korisnik unosi tekst
  };

  const handleInputFocus = () => {
    setIsDropdownOpen(true); // Otvori dropdown kada korisnik klikne na input
  };

  const handleInputBlur = () => {
    setTimeout(() => setIsDropdownOpen(false), 200); // Sačekaj pre zatvaranja (klik na opciju)
  };

  return (
    <div className="searchable-dropdown" ref={dropdownRef}>
      <input
        type="text"
        value={searchTerm || selectedValue} // Prikazuje pretragu ili izabranu vrednost
        placeholder={placeholder}
        onFocus={handleInputFocus}
        onBlur={handleInputBlur}
        onChange={handleInputChange}
      />
      {isDropdownOpen && (
        <ul>
          {filteredOptions.length > 0 ? (
            filteredOptions.map((option, index) => (
              <li
                key={index}
                onClick={() => handleSelect(option)} // Postavlja vrednost na klik
              >
                {option}
              </li>
            ))
          ) : (
            <li>No options found</li>
          )}
        </ul>
      )}
    </div>
  );
}

export default SearchableDropdown;
