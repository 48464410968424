import React from 'react';
import '../css/QuoteHeader.css'; // Dodaj odvojen CSS za stilizaciju

const QuoteHeader = ({ quoteMaster, loading }) => {
  if (loading) {
    return (
      <div className="loading-container">
        <div className="spinner-icon" />
        <p>Loading...</p>
      </div>
    );
  }

  if (!quoteMaster) {
    return <p>No header data found.</p>;
  }

  return (
    <div className="quote-header">
      <h3 className="section-title">Quotation Details</h3>
      <div className="quote-header-section">
        <div className="quote-info">
          <p><strong>Quote Ref:</strong> {quoteMaster?.quote_ref}</p>
          <p><strong>Quote Type:</strong> {quoteMaster?.quote_type}</p>
          <p><strong>Quote Date:</strong> {quoteMaster?.quote_date}</p>
          <p><strong>Valid Till:</strong> {quoteMaster?.quote_valid_till}</p>
        </div>
        <div className="partner-info">
          <p><strong>Partner Name:</strong> {quoteMaster?.partner_name}</p>
          <p><strong>Partner Code:</strong> {quoteMaster?.partner_code}</p>
          <p><strong>Contact:</strong> {quoteMaster?.partner_contact}</p>
        </div>
        <div className="financial-info">
          <p><strong>Total Air:</strong> ${quoteMaster?.total_air}</p>
          <p><strong>Total Sea:</strong> ${quoteMaster?.total_sea}</p>
          <p><strong>Total Local:</strong> ${quoteMaster?.total_local}</p>
        </div>
      </div>
      <div className="quote-notes">
        <p><strong>Description:</strong> {quoteMaster?.quote_desc || 'N/A'}</p>
        <p><strong>Notes:</strong> {quoteMaster?.commodity_desc || 'N/A'}</p>
      </div>
    </div>
  );
};

export default QuoteHeader;
