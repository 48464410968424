import React from "react";
import Card from "./Card";
import "../css/Dashboard.css";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js"; // Import elemenata
import { Doughnut } from "react-chartjs-2";

// Registracija potrebnih elemenata
ChartJS.register(ArcElement, Tooltip, Legend);

function Dashboard({ isSidebarOpen }) {

  const chartData = {
    labels: ["Approved", "Rejected", "Pending"],
    datasets: [
      {
        label: "Split of Quotes Sent",
        data: [45, 25, 30], // Primer podataka
        backgroundColor: ["#4CAF50", "#F44336", "#FFC107"], // Boje za grafikon
        hoverBackgroundColor: ["#45E580", "#F7786B", "#FFD54F"],
      },
    ],
  };

  return (
    <div
      className="dashboard-container"
      style={{
        paddingLeft: isSidebarOpen ? "270px" : "30px", // Dinamički padding
        transition: "padding-left 0.3s ease", // Glatka tranzicija
      }}
    >
      <div className="dashboard-header">
        <h2>Dashboard</h2>
        <div className="user-welcome">
          <p>Welcome, Venkat!</p>
        </div>
        <button className="create-quote">Create Quote</button>
      </div>
      <div className="dashboard-cards">
        <Card
          title="Active Users"
          value="100%"
          icon="fas fa-users"
          status="1 Total in the teams"
        />
        <Card
          title="Avg Margin"
          value="$0"
          icon="fas fa-percent"
          status="-100% Change"
        />
        <Card
          title="Quotes Sent"
          value="0"
          icon="fas fa-file-alt"
          status="100% Change"
        />
        <Card
          title="Accepted"
          value="0 (0%)"
          icon="fas fa-check"
          status="100% Change"
        />
        <Card
          title="Clients"
          value="250"
          icon="fas fa-briefcase"
          status="New clients added"
        />
        <Card
          title="Emails"
          value="120"
          icon="fas fa-envelope"
          status="Emails sent"
        />
        <Card
          title="Calls"
          value="45"
          icon="fas fa-phone-alt"
          status="Calls made"
        />
        <Card
          title="Meetings"
          value="15"
          icon="fas fa-calendar-check"
          status="Meetings scheduled"
        />
        {/* Kartica sa grafikonom */}
        <div className="card">
          <div className="card-header">
            <i className="fas fa-chart-pie"></i>
            <h3>Split of Quotes Sent</h3>
          </div>
          <div className="card-body">
            <Doughnut data={chartData} />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
