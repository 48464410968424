import React, { useState, useEffect } from 'react';
import { FaSave, FaTrash, FaPlus } from 'react-icons/fa';
import Swal from 'sweetalert2';
import supabase from '../config/supaBaseClient';
import '../css/AddRates.css';


const AddRates = ({ portRef, quoteRef }) => {
  const [airRates, setAirRates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRates = async () => {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from('quote_air_rates')
          .select('*')
          .eq('quote_ref', quoteRef)
          .eq('port_ref', portRef);

        if (error) {
          console.error('Error fetching air rates:', error.message);
        } else {
          setAirRates(data || []);
        }
      } catch (err) {
        console.error('Error fetching data:', err.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRates();
  }, [portRef, quoteRef]);

  const handleInputChange = (e, index, field) => {
    const newValue = e.target.value;
    setAirRates((prev) => {
      const updatedRates = [...prev];
      updatedRates[index][field] = newValue;
      return updatedRates;
    });
  };

  const handleAddRate = () => {
    setAirRates((prev) => [
      ...prev,
      {
        company: 'JGLS',
        entity_code: 'SGSIN',
        port_ref: portRef,
        quote_ref: quoteRef,
        itemno: '',
        itemdesc: '',
        carrier_code: '',
        valid_from: '',
        valid_to: '',
        charge_type: 'O',
        rate_type: 'F',
        currency_code: '',
        min_rate: 0,
        lesser_45_rate: 0,
        greater_45_rate: 0,
        greater_100_rate: 0,
        greater_300_rate: 0,
        greater_500_rate: 0,
        greater_1000_rate: 0,
        rate_notes: '',
      },
    ]);
  };

  const handleSave = async (index) => {
    try {
        const updatedRate = airRates[index];

        // Validacija obaveznih polja
        if (
            !updatedRate.itemno ||
            !updatedRate.itemdesc ||
            !updatedRate.carrier_code ||
            !updatedRate.currency_code ||
            !updatedRate.valid_from ||
            !updatedRate.valid_to
        ) {
            Swal.fire({
                icon: 'error',
                title: 'Validation Error',
                text: 'Please fill in all required fields.',
            });
            return;
        }

        // Postavljanje default vrednosti
        updatedRate.quote_ref = quoteRef;
        updatedRate.port_ref = portRef;
        updatedRate.create_user = updatedRate.create_user || 'admin';
        updatedRate.update_user = updatedRate.update_user || 'admin';

        let message = '';
        if (updatedRate.rate_seq) {
            // Update ako već postoji `rate_seq`
            const { error } = await supabase
                .from('quote_air_rates')
                .update(updatedRate)
                .eq('rate_seq', updatedRate.rate_seq);

            if (error) {
                Swal.fire('Error', 'Error updating rate: ' + error.message, 'error');
                return;
            }
            message = 'Rate updated successfully!';
        } else {
            // Dodavanje novog reda
            const { data, error } = await supabase.from('quote_air_rates').insert([updatedRate]);

            if (error) {
                Swal.fire('Error', 'Error adding rate: ' + error.message, 'error');
                return;
            }

            if (data && data.length > 0) {
                // Ažuriraj stanje sa `rate_seq` iz baze
                setAirRates((prev) =>
                    prev.map((rate, i) =>
                        i === index ? { ...rate, rate_seq: data[0].rate_seq } : rate
                    )
                );
                message = 'Rate added successfully!';
            }
        }

        // Prikaz poruke nakon uspešnog umetanja ili ažuriranja
        Swal.fire({
            icon: 'success',
            title: 'Success',
            text: message,
        });
    } catch (error) {
        Swal.fire('Error', 'Unexpected error: ' + error.message, 'error');
    }
};


  const handleDelete = async (index) => {
    try {
      const rate = airRates[index];
      const { error } = await supabase
        .from('quote_air_rates')
        .delete()
        .eq('rate_seq', rate.rate_seq);

      if (!error) {
        setAirRates((prev) => prev.filter((_, i) => i !== index));
        Swal.fire('Success', 'Rate deleted successfully!', 'success');
      } else {
        console.error('Error deleting rate:', error.message);
      }
    } catch (error) {
      console.error('Error deleting data:', error.message);
    }
  };

  return (
    <div className="air-rates-container">
  
      <button onClick={handleAddRate} className="add-rate-btn">
        <FaPlus /> Add AIR Rate
      </button>
      {loading ? (
        <p>Loading rates...</p>
      ) : airRates.length > 0 ? (
        <table className="air-rates-table">
          <thead>
            <tr>
              <th>Item No</th>
              <th>Item Desc</th>
              <th>Carrier Code</th>
              <th>Valid From</th>
              <th>Valid To</th>
              <th>Currency Code</th>
              <th>Min Rate</th>
              <th>&lt;45 Rate</th>
              <th>&gt;45 Rate</th>
              <th>&gt;100 Rate</th>
              <th>&gt;300 Rate</th>
              <th>&gt;500 Rate</th>
              <th>&gt;1000 Rate</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {airRates.map((rate, index) => (
              <tr key={rate.rate_seq || `rate-${index}`}>
                <td>
                  <input
                    type="text"
                    value={rate.itemno}
                    onChange={(e) => handleInputChange(e, index, 'itemno')}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rate.itemdesc}
                    onChange={(e) => handleInputChange(e, index, 'itemdesc')}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rate.carrier_code}
                    onChange={(e) => handleInputChange(e, index, 'carrier_code')}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    value={rate.valid_from}
                    onChange={(e) => handleInputChange(e, index, 'valid_from')}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    value={rate.valid_to}
                    onChange={(e) => handleInputChange(e, index, 'valid_to')}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rate.currency_code}
                    onChange={(e) => handleInputChange(e, index, 'currency_code')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={rate.min_rate}
                    onChange={(e) => handleInputChange(e, index, 'min_rate')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={rate.lesser_45_rate}
                    onChange={(e) => handleInputChange(e, index, 'lesser_45_rate')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={rate.greater_45_rate}
                    onChange={(e) => handleInputChange(e, index, 'greater_45_rate')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={rate.greater_100_rate}
                    onChange={(e) => handleInputChange(e, index, 'greater_100_rate')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={rate.greater_300_rate}
                    onChange={(e) => handleInputChange(e, index, 'greater_300_rate')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={rate.greater_500_rate}
                    onChange={(e) => handleInputChange(e, index, 'greater_500_rate')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={rate.greater_1000_rate}
                    onChange={(e) => handleInputChange(e, index, 'greater_1000_rate')}
                  />
                </td>
                <td style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                  <button onClick={() => handleSave(index)} className="save-btn">
                    <FaSave />
                  </button>
                  <button onClick={() => handleDelete(index)} className="delete-btn">
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="no-rates-message">No AIR rates available.</p>
      )}
    </div>
  );
};

export default AddRates;
