import React from 'react';

function Settings() {
  return (
    <div>
      <h2>Settings</h2>
      {/* Sadržaj za Settings */}
    </div>
  );
}

export default Settings;
