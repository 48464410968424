import React, { useState, useEffect } from 'react';
import { FaSave, FaTrash, FaPlus } from 'react-icons/fa';
import Swal from 'sweetalert2';
import supabase from '../config/supaBaseClient';
import '../css/Rates.css';

const FclRates = ({ portRef, quoteRef }) => {
  const [fclRates, setFclRates] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchRates = async () => {
      try {
        setLoading(true);
        const { data, error } = await supabase
          .from('quote_fcl_rates')
          .select('*')
          .eq('quote_ref', quoteRef)
          .eq('port_ref', portRef);

        if (error) {
          console.error('Error fetching FCL rates:', error.message);
        } else {
          setFclRates(data || []);
        }
      } catch (error) {
        console.error('Error fetching FCL rates:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchRates();
  }, [portRef, quoteRef]);

  const handleInputChange = (e, index, field) => {
    const newValue = e.target.value;
    setFclRates((prev) => {
      const updatedRates = [...prev];
      updatedRates[index][field] = newValue;
      return updatedRates;
    });
  };

  const handleSave = async (index) => {
    try {
      const updatedRate = fclRates[index];

      if (
        !updatedRate.itemno ||
        !updatedRate.itemdesc ||
        !updatedRate.carrier_code ||
        !updatedRate.currency_code ||
        !updatedRate.container_code ||
        !updatedRate.valid_from ||
        !updatedRate.valid_to
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Validation Error',
          text: 'Please fill in all required fields.',
        });
        return;
      }

      updatedRate.quote_ref = quoteRef;
      updatedRate.port_ref = portRef;
      updatedRate.create_user = updatedRate.create_user || 'admin';
      updatedRate.update_user = updatedRate.update_user || 'admin';

      if (updatedRate.rate_seq) {
        // Ažuriranje postojećeg reda
        const { error } = await supabase
          .from('quote_fcl_rates')
          .update(updatedRate)
          .eq('rate_seq', updatedRate.rate_seq);

        if (error) {
          console.error('Error updating rate:', error.message);
          Swal.fire('Error', 'Failed to update the rate.', 'error');
        } else {
          Swal.fire('Success', 'Rate updated successfully!', 'success');
        }
      } else {
        // Dodavanje novog reda
        const { data, error } = await supabase
          .from('quote_fcl_rates')
          .insert([updatedRate])
          .select();

        if (error) {
          console.error('Error saving rate:', error.message);
          Swal.fire('Error', 'Failed to save the rate.', 'error');
        } else {
          const savedRate = { ...updatedRate, rate_seq: data[0].rate_seq };
          setFclRates((prev) => {
            const updatedRates = [...prev];
            updatedRates[index] = savedRate;
            return updatedRates;
          });

          Swal.fire('Success', 'Rate saved successfully!', 'success');
        }
      }
    } catch (error) {
      console.error('Error saving rate:', error.message);
    }
  };

  const handleDelete = async (index) => {
    try {
      const rate = fclRates[index];
      const { error } = await supabase
        .from('quote_fcl_rates')
        .delete()
        .eq('rate_seq', rate.rate_seq);

      if (!error) {
        setFclRates((prev) => prev.filter((_, i) => i !== index));
        Swal.fire('Success', 'Rate deleted successfully!', 'success');
      } else {
        console.error('Error deleting rate:', error.message);
      }
    } catch (error) {
      console.error('Error deleting data:', error.message);
    }
  };

  const handleAddRate = () => {
    const today = new Date().toISOString().split('T')[0];

    const newRate = {
      company: 'JGLS',
      entity_code: 'SGSIN',
      port_ref: portRef,
      quote_ref: quoteRef,
      itemno: '',
      itemdesc: '',
      carrier_code: '',
      valid_from: today,
      valid_to: today,
      charge_type: 'O',
      rate_type: 'F',
      currency_code: '',
      container_code: '',
      item_rate: 0,
    };

    setFclRates((prev) => [...prev, newRate]);
  };

  return (
    <div className="fcl-rates-container">
      <h4>FCL Rates for Port Ref: {portRef}</h4>
      <button onClick={handleAddRate} className="add-rate-btn">
        <FaPlus /> Add FCL Rate
      </button>
      {loading ? (
        <p>Loading rates...</p>
      ) : fclRates.length > 0 ? (
        <table className="fcl-rates-table">
          <thead>
            <tr>
              <th>Item No</th>
              <th>Item Desc</th>
              <th>Carrier Code</th>
              <th>Valid From</th>
              <th>Valid To</th>
              <th>Currency Code</th>
              <th>Container Code</th>
              <th>Rate</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {fclRates.map((rate, index) => (
              <tr key={`fcl-rate-${index}`}>
                <td>
                  <input
                    type="text"
                    value={rate.itemno}
                    onChange={(e) => handleInputChange(e, index, 'itemno')}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rate.itemdesc}
                    onChange={(e) => handleInputChange(e, index, 'itemdesc')}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rate.carrier_code}
                    onChange={(e) => handleInputChange(e, index, 'carrier_code')}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    value={rate.valid_from}
                    onChange={(e) => handleInputChange(e, index, 'valid_from')}
                  />
                </td>
                <td>
                  <input
                    type="date"
                    value={rate.valid_to}
                    onChange={(e) => handleInputChange(e, index, 'valid_to')}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rate.currency_code}
                    onChange={(e) => handleInputChange(e, index, 'currency_code')}
                  />
                </td>
                <td>
                  <input
                    type="text"
                    value={rate.container_code}
                    onChange={(e) => handleInputChange(e, index, 'container_code')}
                  />
                </td>
                <td>
                  <input
                    type="number"
                    value={rate.item_rate}
                    onChange={(e) => handleInputChange(e, index, 'item_rate')}
                  />
                </td>
                <td style={{ display: "flex", gap: "10px", justifyContent: "flex-start" }}>
                  <button onClick={() => handleSave(index)} className="save-btn">
                    <FaSave />
                  </button>
                  <button onClick={() => handleDelete(index)} className="delete-btn">
                    <FaTrash />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p className="no-rates-message">No FCL rates available.</p>
      )}
    </div>
  );
};

export default FclRates;
