import React, { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import "../css/Sidebar.css";

function Sidebar({ onToggleSidebar }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Novo stanje za sidebar
  const [isSettingsOpen, setIsSettingsOpen] = useState(false);
  const [isRatesOpen, setIsRatesOpen] = useState(false); // Dodano stanje za Rates
  const location = useLocation(); // Koristi se za aktivnu stavku

  const toggleSidebar = () => {
    const newIsSidebarOpen = !isSidebarOpen; // Novo stanje (obrnut trenutnog)
    setIsSidebarOpen(newIsSidebarOpen); // Ažuriraj lokalno stanje
    onToggleSidebar(newIsSidebarOpen); // Obavesti roditelja o promeni
  };


  const toggleSettingsMenu = () => {
    setIsSettingsOpen(!isSettingsOpen);
  };

  const toggleRatesMenu = () => {
    setIsRatesOpen(!isRatesOpen); // Upravljanje stanjem za Rates
  };

  const isActive = (path) => location.pathname === path;

  return (
    <>
    {/* Hamburger dugme */}
    <button className="hamburger" onClick={toggleSidebar}>
        <i className={`fas ${isSidebarOpen ? "fa-times" : "fa-bars"}`}></i>
      </button>
      {/* Sidebar */}
      <div className={`sidebar ${isSidebarOpen ? "open" : "closed"}`}>
        <h2 className="sidebar-logo">Price Sense</h2>

        <ul>
          <li className={isActive("/dashboard") ? "active" : ""}>
            <Link to="/dashboard">
              <i className="fas fa-tachometer-alt"></i> Dashboard
            </Link>
          </li>
          <li className={isActive("/quotations") ? "active" : ""}>
            <Link to="/quotations">
              <i className="fas fa-file-invoice"></i> Quotations
            </Link>
          </li>

          {/* Rates */}
          <li className={`rates-item ${isRatesOpen ? "open" : ""}`}>
            <div onClick={toggleRatesMenu}>
              <i className="fas fa-tags"></i> Rates
              <i className={`fas ${isRatesOpen ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
            </div>
            {/* Submenu */}
            {isRatesOpen && (
              <ul className="submenu">
                <li className={isActive("/fcl-rates") ? "active" : ""}>
                  <Link to="/fcl-rates">
                    <i className="fas fa-box"></i> FCL
                  </Link>
                </li>
                <li className={isActive("/lcl-rates") ? "active" : ""}>
                  <Link to="/lcl-rates">
                    <i className="fas fa-boxes"></i> LCL
                  </Link>
                </li>
                <li className={isActive("/air-rates") ? "active" : ""}>
                  <Link to="/air-rates">
                    <i className="fas fa-plane"></i> AIR
                  </Link>
                </li>
                <li className={isActive("/handling-rates") ? "active" : ""}>
                  <Link to="/handling-rates">
                    <i className="fas fa-hands-helping"></i> Handling
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Clients */}
          <li className={isActive("/clients") ? "active" : ""}>
            <Link to="/clients">
              <i className="fas fa-users"></i> Clients
            </Link>
          </li>

          {/* Settings */}
          <li className={`settings-item ${isSettingsOpen ? "open" : ""}`}>
            <div onClick={toggleSettingsMenu}>
              <i className="fas fa-cogs"></i> Settings
              <i className={`fas ${isSettingsOpen ? "fa-chevron-up" : "fa-chevron-down"}`}></i>
            </div>
            {/* Submenu */}
            {isSettingsOpen && (
              <ul className="submenu">
                <li className={isActive("/margins") ? "active" : ""}>
                  <Link to="/margins">
                    <i className="fas fa-percent"></i> Margins
                  </Link>
                </li>
                <li className={isActive("/surcharges") ? "active" : ""}>
                  <Link to="/surcharges">
                    <i className="fas fa-money-bill-wave"></i> Surcharges
                  </Link>
                </li>
                <li className={isActive("/holidays") ? "active" : ""}>
                  <Link to="/holidays">
                    <i className="fas fa-calendar-alt"></i> Holidays
                  </Link>
                </li>
                <li className={isActive("/organization") ? "active" : ""}>
                  <Link to="/organization">
                    <i className="fas fa-building"></i> Organization
                  </Link>
                </li>
                <li className={isActive("/terms-and-conditions") ? "active" : ""}>
                  <Link to="/terms-and-conditions">
                    <i className="fas fa-file-contract"></i> Terms and Conditions
                  </Link>
                </li>
                <li className={isActive("/users") ? "active" : ""}>
                  <Link to="/users">
                    <i className="fas fa-users-cog"></i> Users
                  </Link>
                </li>
              </ul>
            )}
          </li>

          {/* Feedback */}
          <li className={isActive("/feedback") ? "active" : ""}>
            <Link to="/feedback">
              <i className="fas fa-comment-dots"></i> Feedback
            </Link>
          </li>

          {/* Logs */}
          <li className={isActive("/logs") ? "active" : ""}>
            <Link to="/logs">
              <i className="fas fa-file-alt"></i> Logs
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
}

export default Sidebar;
