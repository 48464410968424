import React from 'react';

function Feedback() {
  return (
    <div>
      <h2>Feedback</h2>
      {/* Sadržaj za Feedback */}
    </div>
  );
}

export default Feedback;
