import React from "react";
import '../css/Card.css'; // CSS za karticu

function Card({ title, value, icon = "fas fa-info-circle", status }) {
  return (
    <div className="card">
      <div className="card-header">
        <i className={icon}></i> {/* Ikona */}
        <h3>{title}</h3>
      </div>
      <div className="card-body">
        <h4>{value}</h4>
        <p>{status}</p>
      </div>
    </div>
  );
}

export default Card;
