import React, { useState } from "react";
import Sidebar from "./components/Sidebar"


function Layout({ children }) {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true); // Stanje za Sidebar

  const handleToggleSidebar = (isOpen) => {
    setIsSidebarOpen(isOpen); // Ažuriranje stanja Sidebar-a
  };

  return (
    <div className="layout-container">
      <Sidebar onToggleSidebar={handleToggleSidebar} />
      <div className="content">
        {React.cloneElement(children, { isSidebarOpen })} {/* Prosleđivanje isSidebarOpen */}
      </div>
    </div>
  );
}

export default Layout;
