import React from 'react';
import ReactDOM from 'react-dom/client'; // Import za React 18
import App from './App'; // Glavna komponenta

// Pronalazak korenskog elementa
const rootElement = document.getElementById('root');

// Kreiranje root-a sa novim API-jem
const root = ReactDOM.createRoot(rootElement);

// Renderovanje aplikacije
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);
