import React, { useState, useEffect } from 'react';
import { FaSave, FaTrash, FaPlus, FaFileExport, FaCheckCircle } from 'react-icons/fa';
import Swal from 'sweetalert2';
import supabase from '../../config/supaBaseClient';
import '../../css/RateManagement/RateFcl_m.css';
import Papa from 'papaparse';

function RateLcl_m({ isSidebarOpen }) {
  const [rates, setRates] = useState([]);
  const [filteredRates, setFilteredRates] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });

  const [selectedRates, setSelectedRates] = useState([]);
  const [filters, setFilters] = useState({
    validTillDate: '',
    carrierSearch: '',
    fromToPort: '',
    fromToCountry: '',
  });
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState('');

  useEffect(() => {
    const filtered = rates.filter((rate) =>
      Object.values(rate).some((value) =>
        String(value).toLowerCase().includes(search.toLowerCase())
      )
    );
    setFilteredRates(filtered);
  }, [search, rates]);

  const fetchFilteredRates = async () => {
    try {
      setLoading(true);
      let query = supabase.from('rates_lcl').select('*');

      if (filters.validTillDate) {
        query = query.eq('valid_to', filters.validTillDate);
      }
      if (filters.carrierSearch) {
        query = query.ilike('carrier_code', `%${filters.carrierSearch}%`);
      }
      if (filters.fromToPort) {
        query = query.ilike('from_port', `%${filters.fromToPort}%`);
      }
      if (filters.fromToCountry) {
        query = query.ilike('from_country', `%${filters.fromToCountry}%`);
      }

      query = query.range((currentPage - 1) * rowsPerPage, currentPage * rowsPerPage - 1);

      const { data, error } = await query;

      if (error) throw new Error(error.message);

      setRates(data);
      setFilteredRates(data);
    } catch (error) {
      Swal.fire('Error', error.message, 'error');
    } finally {
      setLoading(false);
    }
  };

  
  const handleAddRate = () => {
    const newRate = {
      company: 'JGLS',
      entity_code: 'SGSIN',
      job_type: '',
      valid_from: new Date().toISOString().split('T')[0],
      valid_to: '',
      carrier_code: '',
      from_port: '',
      from_port_name: 'Default Port Name',
      from_country: '',
      to_port: '',
      to_port_name: '',
      to_country: '',
      itemno: '',
      itemdesc: '',
      currency_code: '',
      item_uom: '',
      from_item_unit: 1,
      to_item_unit: 1,
      item_rate: 0,
      min_rate: 0,
      rate_notes: '',
      create_date: new Date().toISOString(),
      create_user: 'admin',
      update_date: new Date().toISOString(),
      update_user: 'admin',
    };

    setRates((prev) => [...prev, newRate]);
  };
  const handleSave = async (index) => {
    try {
      const rateToSave = { ...rates[index] };
  
      // Validacija polja pre čuvanja
      if (
        !rateToSave.valid_to ||
        !rateToSave.carrier_code ||
        !rateToSave.from_port ||
        !rateToSave.to_port ||
        !rateToSave.itemno ||
        !rateToSave.currency_code
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Validation Error',
          text: 'Please fill in all required fields.',
        });
        return;
      }
  
      // Priprema podataka za čuvanje
      const payload = {
        company: rateToSave.company,
        entity_code: rateToSave.entity_code,
        job_type: rateToSave.job_type,
        valid_from: rateToSave.valid_from,
        valid_to: rateToSave.valid_to,
        carrier_code: rateToSave.carrier_code,
        from_port: rateToSave.from_port,
        from_port_name: rateToSave.from_port_name,
        from_country: rateToSave.from_country,
        to_port: rateToSave.to_port,
        to_port_name: rateToSave.to_port_name,
        to_country: rateToSave.to_country,
        itemno: rateToSave.itemno,
        itemdesc: rateToSave.itemdesc,
        currency_code: rateToSave.currency_code,
        item_uom: rateToSave.item_uom,
        from_item_unit: rateToSave.from_item_unit,
        to_item_unit: rateToSave.to_item_unit,
        item_rate: rateToSave.item_rate,
        min_rate: rateToSave.min_rate,
        rate_notes: rateToSave.rate_notes,
        create_date: rateToSave.create_date || new Date().toISOString(),
        create_user: rateToSave.create_user || 'admin',
        update_date: new Date().toISOString(),
        update_user: 'admin',
      };
  
      // Ažuriranje postojećeg unosa
      if (rateToSave.rate_seq) {
        const { error } = await supabase
          .from('rates_lcl')
          .update(payload)
          .eq('rate_seq', rateToSave.rate_seq);
  
        if (error) throw new Error(`Error updating rate: ${error.message}`);
        Swal.fire('Success', 'Rate updated successfully!', 'success');
      } 
      // Umetanje novog unosa
      else {
        const { error: insertError } = await supabase.from('rates_lcl').insert([payload]);
  
        if (insertError) throw new Error(`Error inserting rate: ${insertError.message}`);
  
        const { data: insertedRate } = await supabase
          .from('rates_lcl')
          .select('*')
          .order('rate_seq', { ascending: false })
          .limit(1);
  
        if (insertedRate && insertedRate.length > 0) {
          setRates((prev) => {
            const updatedRates = [...prev];
            updatedRates[index] = { ...payload, rate_seq: insertedRate[0].rate_seq };
            return updatedRates;
          });
          Swal.fire('Success', 'New rate added successfully!', 'success');
        }
      }
    } catch (error) {
      Swal.fire('Error', error.message, 'error');
    }
  };
  
  const handleDelete = async (rateSeq, index) => {
    try {
      if (!rateSeq) {
        Swal.fire('Error', 'Cannot delete rate without a valid rate_seq.', 'error');
        return;
      }

      const { error } = await supabase.from('rates_lcl').delete().eq('rate_seq', rateSeq);

      if (error) throw new Error(error.message);

      setRates((prev) => prev.filter((_, i) => i !== index));
      Swal.fire('Success', 'Rate deleted successfully!', 'success');
    } catch (error) {
      Swal.fire('Error', error.message, 'error');
    }
  };
  const handlePageChange = (direction) => {
    if (direction === 'prev' && currentPage > 1) {
      setCurrentPage((prev) => prev - 1);
    } else if (direction === 'next') {
      setCurrentPage((prev) => prev + 1);
    }
  };

  const handleInputChange = (index, field, value) => {
    setRates((prevRates) => {
      const updatedRates = [...prevRates];
      updatedRates[index][field] = value;
      return updatedRates;
    });
  };

  const sortRates = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
  
    const sortedRates = [...filteredRates].sort((a, b) => {
      if (a[key] < b[key]) {
        return direction === 'asc' ? -1 : 1;
      }
      if (a[key] > b[key]) {
        return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  
    setFilteredRates(sortedRates);
    setSortConfig({ key, direction });
  };
  const handleExportCSV = () => {
    if (filteredRates.length === 0) {
      Swal.fire('Error', 'No data available to export.', 'error');
      return;
    }
  
    const csvContent =
      'data:text/csv;charset=utf-8,' +
      [
        Object.keys(filteredRates[0]).join(','), // Zaglavlje (nazivi kolona)
        ...filteredRates.map((row) =>
          Object.values(row)
            .map((value) => `"${value}"`) // Escape za sigurnost
            .join(',')
        ),
      ].join('\n');
  
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement('a');
    link.setAttribute('href', encodedUri);
    link.setAttribute('download', 'rates_lcl.csv');
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  
  const handleExportJSON = () => {
    if (filteredRates.length === 0) {
      Swal.fire('Error', 'No data available to export.', 'error');
      return;
    }
  
    const jsonContent = JSON.stringify(filteredRates, null, 2); // Lepo formatiran JSON
    const blob = new Blob([jsonContent], { type: 'application/json' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'rates_lcl.json';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };
  const handleImportCSV = async (event) => {
    const file = event.target.files[0];
  
    if (!file) {
      Swal.fire('Error', 'No file selected.', 'error');
      return;
    }
  
    if (!file.name.endsWith('.csv')) {
      Swal.fire('Error', 'Please upload a valid CSV file.', 'error');
      return;
    }
  
    setIsLoading(true);
  
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async (result) => {
        setIsLoading(false);
  
        if (result.errors.length > 0) {
          Swal.fire('Error', 'Error parsing the file.', 'error');
          console.error(result.errors);
          return;
        }
  
        const importedData = result.data.map((row) => ({
          ...row,
          create_date: new Date().toISOString(),
          create_user: 'admin',
          update_date: new Date().toISOString(),
          update_user: 'admin',
        }));
  
        try {
          const { error } = await supabase.from('rates_lcl').insert(importedData);
  
          if (error) {
            throw new Error(`Error saving imported data: ${error.message}`);
          }
  
          setRates((prevRates) => [...prevRates, ...importedData]);
          Swal.fire('Success', 'Data imported successfully!', 'success');
        } catch (dbError) {
          Swal.fire('Error', dbError.message, 'error');
          console.error(dbError);
        }
      },
      error: (parseError) => {
        setIsLoading(false);
        Swal.fire('Error', 'Error reading the file.', 'error');
        console.error(parseError);
      },
    });
  };
    
  return (
    <div className={`rates-table-container ${isSidebarOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
    <h3>Rates Management - LCL</h3>
  
    {isLoading && (
      <div className="loading-overlay">
        <div className="loading-content">
          <div className="spinner"></div>
          <p>Processing... {progress}%</p>
        </div>
      </div>
    )}
      <div className="table-controls">
  <button onClick={handleAddRate} className="btn-add">
    <FaPlus /> Add New Rate
  </button>

  <div className="dropdown import-dropdown">
    <button className="dropdown-toggle">Import</button>
    <ul className="dropdown-menu">
      <li>
        <label htmlFor="csv-upload" style={{ cursor: 'pointer' }}>
          Import CSV
        </label>
        <input
          id="csv-upload"
          type="file"
          accept=".csv"
          onChange={handleImportCSV}
          style={{ display: 'none' }}
        />
      </li>
      <li>Copy to Quote</li>
      <li>Import to Quote</li>
    </ul>
  </div>

  <div className="dropdown export-dropdown">
    <button className="dropdown-toggle">Export</button>
    <ul className="dropdown-menu">
      <li onClick={handleExportCSV}>Export as CSV</li>
      <li onClick={handleExportJSON}>Export as JSON</li>
    </ul>
  </div>
</div>

      <div className="filters-container">
  <input
    type="date"
    placeholder="Valid Till Date"
    value={filters.validTillDate}
    onChange={(e) => setFilters({ ...filters, validTillDate: e.target.value })}
    className="filter-input"
  />
  <input
    type="text"
    placeholder="Import/Export/Xtrade"
    value={filters.importExportXtrade}
    onChange={(e) => setFilters({ ...filters, importExportXtrade: e.target.value })}
    className="filter-input"
  />
  <input
    type="text"
    placeholder="Carrier Search"
    value={filters.carrierSearch}
    onChange={(e) => setFilters({ ...filters, carrierSearch: e.target.value })}
    className="filter-input"
  />
  <input
    type="text"
    placeholder="Origin/Destination"
    value={filters.originDestination}
    onChange={(e) => setFilters({ ...filters, originDestination: e.target.value })}
    className="filter-input"
  />
  <input
    type="text"
    placeholder="From/To Port Search"
    value={filters.fromToPort}
    onChange={(e) => setFilters({ ...filters, fromToPort: e.target.value })}
    className="filter-input"
  />
  <input
    type="text"
    placeholder="From/To Country"
    value={filters.fromToCountry}
    onChange={(e) => setFilters({ ...filters, fromToCountry: e.target.value })}
    className="filter-input"
  />
  <input
    type="text"
    placeholder="IncoTerms"
    value={filters.incoTerms}
    onChange={(e) => setFilters({ ...filters, incoTerms: e.target.value })}
    className="filter-input"
  />
  <button onClick={fetchFilteredRates} className="filter-btn">
    Search
  </button>
</div>
{loading ? (
  <div className="loading-container">
    <div className="spinner"></div>
    <p>Loading... Please wait</p>
  </div>
) : (
  <>
 <div className="results-and-search">
  <div className="results-counter">
    {filteredRates.length > 0 ? (
      <div className="results-box">
        <span className="results-icon">🔍</span>
        <span className="results-text">
          Found <strong>{filteredRates.length}</strong> result(s)
        </span>
      </div>
    ) : (
      <div className="results-box no-results">
        <span className="results-icon">😞</span>
        <span className="results-text">No results found</span>
      </div>
    )}
  </div>

  <div className="search-pagination-wrapper">
  <div className="search-container">
    <div className="search-wrapper">
      <span className="search-icon">🔍</span>
      <input
        type="text"
        placeholder="Search..."
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        className="search-input"
      />
    </div>
  </div>
  <div className="pagination-container">
    <button
      onClick={() => handlePageChange('prev')}
      disabled={currentPage === 1}
      className="pagination-btn"
    >
      Prev
    </button>
    <span className="pagination-info">
      Page {currentPage} of {Math.ceil(filteredRates.length / rowsPerPage)}
    </span>
    <button
      onClick={() => handlePageChange('next')}
      disabled={currentPage === Math.ceil(filteredRates.length / rowsPerPage)}
      className="pagination-btn"
    >
      Next
    </button>
    <select
      value={rowsPerPage}
      onChange={(e) => setRowsPerPage(Number(e.target.value))}
      className="pagination-select"
    >
      <option value={5}>5</option>
      <option value={10}>10</option>
      <option value={20}>20</option>
    </select>
  </div>
</div>
</div>


      <table className="rates-table">
      <thead>
  <tr>
    <th>
      <input
        type="checkbox"
        checked={selectedRates.length === filteredRates.length}
        onChange={() =>
          setSelectedRates(
            selectedRates.length === filteredRates.length
              ? []
              : filteredRates.map((rate) => rate.rate_seq)
          )
        }
      />
    </th>
    <th onClick={() => sortRates('job_type')}>
      Job Type {sortConfig.key === 'job_type' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('valid_from')}>
      Valid From {sortConfig.key === 'valid_from' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('valid_to')}>
      Valid To {sortConfig.key === 'valid_to' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('carrier_code')}>
      Carrier Code {sortConfig.key === 'carrier_code' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('from_port')}>
      From Port {sortConfig.key === 'from_port' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('to_port')}>
      To Port {sortConfig.key === 'to_port' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('container_code')}>
      Container Code {sortConfig.key === 'container_code' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('itemno')}>
      Item No {sortConfig.key === 'itemno' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('item_rate')}>
      Item Rate {sortConfig.key === 'item_rate' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th onClick={() => sortRates('currency_code')}>
      Currency Code {sortConfig.key === 'currency_code' && (sortConfig.direction === 'asc' ? '▲' : '▼')}
    </th>
    <th>Actions</th>
  </tr>
</thead>
<tbody>
  {filteredRates.map((rate, index) => (
    <tr key={`lcl-rate-${index}`}>
      <td>
        <div className="checkbox-wrapper">
          <input
            type="checkbox"
            id={`select-${index}`}
            checked={selectedRates.includes(rate.rate_seq)}
            onChange={() =>
              setSelectedRates((prev) =>
                prev.includes(rate.rate_seq)
                  ? prev.filter((id) => id !== rate.rate_seq)
                  : [...prev, rate.rate_seq]
              )
            }
          />
          <label htmlFor={`select-${index}`} className="checkbox-label">
            {selectedRates.includes(rate.rate_seq) && <FaCheckCircle className="check-icon" />}
          </label>
        </div>
      </td>
      <td>
        <input
          type="text"
          value={rate.job_type || ''}
          onChange={(e) => handleInputChange(index, 'job_type', e.target.value)}
          className="editable-input"
        />
      </td>
      <td>
        <input
          type="date"
          value={rate.valid_from || ''}
          onChange={(e) => handleInputChange(index, 'valid_from', e.target.value)}
          className="editable-input"
        />
      </td>
      <td>
        <input
          type="date"
          value={rate.valid_to || ''}
          onChange={(e) => handleInputChange(index, 'valid_to', e.target.value)}
          className="editable-input"
        />
      </td>
      <td>
        <input
          type="text"
          value={rate.carrier_code || ''}
          onChange={(e) => handleInputChange(index, 'carrier_code', e.target.value)}
          className="editable-input"
        />
      </td>
      <td>
        <input
          type="text"
          value={rate.from_port || ''}
          onChange={(e) => handleInputChange(index, 'from_port', e.target.value)}
          className="editable-input"
        />
      </td>
      <td>
        <input
          type="text"
          value={rate.to_port || ''}
          onChange={(e) => handleInputChange(index, 'to_port', e.target.value)}
          className="editable-input"
        />
      </td>
      <td>
        <input
          type="text"
          value={rate.container_code || ''}
          onChange={(e) => handleInputChange(index, 'container_code', e.target.value)}
          className="editable-input"
        />
      </td>
      <td>
        <input
          type="text"
          value={rate.itemno || ''}
          onChange={(e) => handleInputChange(index, 'itemno', e.target.value)}
          className="editable-input"
        />
      </td>
      <td>
        <input
          type="number"
          value={rate.item_rate || ''}
          onChange={(e) => handleInputChange(index, 'item_rate', e.target.value)}
          className="editable-input"
        />
      </td>
      <td>
        <input
          type="text"
          value={rate.currency_code || ''}
          onChange={(e) => handleInputChange(index, 'currency_code', e.target.value)}
          className="editable-input"
        />
      </td>
      <td>
        <div className="action-buttons">
          <button onClick={() => handleSave(index)} className="save-btn">
            <FaSave />
          </button>
          <button onClick={() => handleDelete(rate.rate_seq, index)} className="delete-btn">
            <FaTrash />
          </button>
        </div>
      </td>
    </tr>
  ))}
</tbody>


      </table>
     </>
)}
    </div>
  )
}

export default RateLcl_m;
