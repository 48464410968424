import React, { useState, useEffect } from 'react';
import { jsPDF } from 'jspdf'; // Import jsPDF for PDF export
import supabase from '../config/supaBaseClient.js';
import { CSVLink } from 'react-csv'; // For CSV export
import * as XLSX from 'xlsx'; // For Excel export
import DatePicker from 'react-datepicker'; // Import react-datepicker
import { startOfDay, endOfDay, startOfWeek, endOfWeek, startOfMonth, endOfMonth, subDays } from 'date-fns'; // Date-fns for advanced date filters
import 'react-datepicker/dist/react-datepicker.css'; // Styles for the datepicker
import '../css/Quotations.css';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';

function Quotations({ isSidebarOpen }) {
  const [quotations, setQuotations] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [filterStatus, setFilterStatus] = useState('');
  const [isStatusButtonsVisible, setIsStatusButtonsVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [quotationsPerPage] = useState(10); // Number of quotations per page
  const [selectedDate, setSelectedDate] = useState(null); // Date picker state
  const [dateFilterType, setDateFilterType] = useState('day'); // Default filter by day
  const [filteredQuotations, setFilteredQuotations] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // Novo stanje za praćenje učitavanja
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'asc' });


  const navigate = useNavigate();

  const statuses = [
    { value: '0', label: 'Open', color: '#FFA500' },
    { value: '1', label: 'Accepted', color: '#28a745' },
    { value: '2', label: 'Rejected', color: '#dc3545' },
    { value: '3', label: 'Cancelled', color: '#6c757d' },
  ];

  useEffect(() => {
    const fetchQuotations = async () => {
      setIsLoading(true); 
      const { data, error } = await supabase.from('quote_master').select('*');
      if (error) {
        console.error('Error fetching quotations:', error.message);
      } else {
        setQuotations(data);
        setFilteredQuotations(data); // Initialize filteredQuotations with fetched data
      }
      setIsLoading(false); 
    };
   
    fetchQuotations();
  }, []);

  const filterQuotationsByDate = (date, filterType) => {
    let filteredData = [...quotations];

    if (date) {
      let startDate, endDate;
      switch (filterType) {
        case 'day':
          startDate = startOfDay(date);
          endDate = endOfDay(date);
          break;
        case 'week':
          startDate = startOfWeek(date, { weekStartsOn: 1 });
          endDate = endOfWeek(date, { weekStartsOn: 1 });
          break;
        case 'month':
          startDate = startOfMonth(date);
          endDate = endOfMonth(date);
          break;
        case 'last30days':
          startDate = startOfDay(subDays(new Date(), 30));
          endDate = endOfDay(new Date());
          break;
        default:
          startDate = startOfDay(date);
          endDate = endOfDay(date);
          break;
      }
      filteredData = filteredData.filter(q => {
        const quoteDate = new Date(q.quote_date);
        return quoteDate >= startDate && quoteDate <= endDate;
      });
    }

    setFilteredQuotations(filteredData);
  };

  const indexOfLastQuotation = currentPage * quotationsPerPage;
  const indexOfFirstQuotation = indexOfLastQuotation - quotationsPerPage;
  const currentQuotations = filteredQuotations.slice(indexOfFirstQuotation, indexOfLastQuotation);

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('sr-RS', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric',
    }).format(new Date(date));
  };
  

  const handleRowSelection = (quote_ref) => {
    if (selectedRows.includes(quote_ref)) {
      setSelectedRows(selectedRows.filter((item) => item !== quote_ref));
    } else {
      setSelectedRows([...selectedRows, quote_ref]);
    }
  };

  const handleSelectAll = () => {
    if (selectedRows.length === filteredQuotations.length) {
      setSelectedRows([]);
    } else {
      setSelectedRows(filteredQuotations.map((quotation) => quotation.quote_ref));
    }
  };

  const handlePageChange = (pageNumber) => setCurrentPage(pageNumber);

  const handleRowDoubleClick = (quote_ref) => {
    navigate(`/quotation-details/${quote_ref}`);
  };

  const filteredQuotationsBySearch = currentQuotations.filter((q) => {
    return (
      (q.partner_name?.toLowerCase().includes(searchQuery.toLowerCase()) ||
        q.quote_desc?.toLowerCase().includes(searchQuery.toLowerCase())) &&
      (filterStatus ? q.quote_status === filterStatus : true)
    );
  });

  const isRowSelected = selectedRows.length > 0; // Check if any row is selected

  const changeStatus = (newStatus) => {
    const updatedQuotations = quotations.map((quotation) => {
      if (selectedRows.includes(quotation.quote_ref)) {
        return { ...quotation, quote_status: newStatus };
      }
      return quotation;
    });
    setQuotations(updatedQuotations);
    setFilteredQuotations(updatedQuotations);

    selectedRows.forEach(async (quote_ref) => {
      const { error } = await supabase
        .from('quote_master')
        .update({ quote_status: newStatus })
        .eq('quote_ref', quote_ref);
      if (error) {
        console.error('Error updating status:', error.message);
      }
    });
  };
  const handleSort = (key) => {
    let direction = 'asc';
    if (sortConfig.key === key && sortConfig.direction === 'asc') {
      direction = 'desc';
    }
    
    const sortedData = [...filteredQuotations].sort((a, b) => {
      if (a[key] < b[key]) return direction === 'asc' ? -1 : 1;
      if (a[key] > b[key]) return direction === 'asc' ? 1 : -1;
      return 0;
    });
    
    setFilteredQuotations(sortedData);
    setSortConfig({ key, direction });
  };
  
  const downloadPDF = () => {
    const doc = new jsPDF();

    // Add content to the PDF
    doc.text('Quotations', 20, 20);
    filteredQuotationsBySearch.forEach((quotation, index) => {
      doc.text(`Quote Ref: ${quotation.quote_ref}`, 20, 30 + index * 10);
      doc.text(`Partner: ${quotation.partner_name}`, 20, 40 + index * 10);
      // Add other fields here as needed
    });

    doc.save('quotations.pdf');
  };

  const exportToExcel = () => {
    const ws = XLSX.utils.json_to_sheet(filteredQuotationsBySearch);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Quotations');
    XLSX.writeFile(wb, 'quotations.xlsx');
  };
  const handleDelete = async () => {
    if (selectedRows.length === 0) {
      Swal.fire('No rows selected', 'Please select rows to delete.', 'info');
      return;
    }
  
    const result = await Swal.fire({
      title: 'Are you sure?',
      text: `You are about to delete ${selectedRows.length} quotation(s). This action cannot be undone.`,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'Cancel',
    });
  
    if (result.isConfirmed) {
      try {
        const { error } = await supabase
          .from('quote_master')
          .delete()
          .in('quote_ref', selectedRows);
  
        if (error) {
          Swal.fire('Error', `Error deleting quotations: ${error.message}`, 'error');
        } else {
          Swal.fire('Deleted', 'Selected quotations have been deleted.', 'success');
          // Update state after deletion
          setQuotations((prev) =>
            prev.filter((quotation) => !selectedRows.includes(quotation.quote_ref))
          );
          setFilteredQuotations((prev) =>
            prev.filter((quotation) => !selectedRows.includes(quotation.quote_ref))
          );
          setSelectedRows([]); // Clear the selection
        }
      } catch (error) {
        console.error('Error deleting quotations:', error);
        Swal.fire('Error', 'Unexpected error occurred while deleting.', 'error');
      }
    }
  };
  return (
    <div
    className={`quotations-container ${
      isSidebarOpen ? "sidebar-open" : "sidebar-closed"
    }`}
  >
      <div className="header">
        <div className="left-header">
          <h1 className="quotes-title">QUOTATION</h1>
        </div>

        <div className="right-header">
          <div className="controls">
            <input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              className="search-input"
            />
            <select
              value={filterStatus}
              onChange={(e) => setFilterStatus(e.target.value)}
              className="filter-select"
            >
              <option value="">All Statuses</option>
              {statuses.map((status) => (
                <option key={status.value} value={status.value}>
                  {status.label}
                </option>
              ))}
            </select>
          </div>

          <div className="date-filter">
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                setSelectedDate(date);
                filterQuotationsByDate(date, dateFilterType);
              }}
              dateFormat="yyyy-MM-dd"
              placeholderText="Select a date"
            />
            <select
              value={dateFilterType}
              onChange={(e) => {
                setDateFilterType(e.target.value);
                if (selectedDate) {
                  filterQuotationsByDate(selectedDate, e.target.value);
                }
              }}
              className="date-filter-select"
            >
              <option value="day">Day</option>
              <option value="week">Week</option>
              <option value="month">Month</option>
              <option value="last30days">Last 30 Days</option>
            </select>
          </div>
        </div>
      </div>

      <div className="action-export-container">
        <div className="actions">
          <button className="add-quote-btn"  onClick={() => navigate('/addquote')}>
            <i className="fa fa-plus"></i> Add Quote
          </button>
          <button
            className="status-btn"
            onClick={() => setIsStatusButtonsVisible(!isStatusButtonsVisible)}
            disabled={!isRowSelected}
          >
            <i className="fa fa-cogs"></i> Change Status
          </button>
          {isStatusButtonsVisible && (
            <div className="status-options">
              {statuses.map((status) => (
                <button
                  key={status.value}
                  style={{ backgroundColor: status.color }}
                  onClick={() => changeStatus(status.value)}
                  disabled={!isRowSelected}
                >
                  {status.label}
                </button>
              ))}
            </div>
          )}
          <button className="delete-btn" onClick={handleDelete} disabled={!isRowSelected}>
            <i className="fa fa-trash"></i> Delete
          </button>
        </div>

        <div className="export-links">
          <CSVLink
            data={filteredQuotationsBySearch}
            className="csv-link"
            disabled={!isRowSelected}
          >
            Export CSV
          </CSVLink>
          {/* <button
            className="delete-btn" 
            onClick={exportToExcel}
            disabled={!isRowSelected}
          >
            Export Excel
          </button> */}
          {/* <button
             className="delete-btn" 
            onClick={downloadPDF}
            disabled={!isRowSelected}
          >
            Download PDF
          </button> */}
        </div>
      </div>
      {isLoading ? (
  <div className="loading-indicator">
    <div className="loading-icon"></div>
  </div>
) : (
      <table className="quotation-table">
      <thead>
  <tr>
    <th>
      <input
        type="checkbox"
        checked={selectedRows.length === filteredQuotations.length}
        onChange={handleSelectAll}
      />
    </th>
    <th onClick={() => handleSort('quote_ref')}>
      Quote Ref {sortConfig.key === 'quote_ref' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
    </th>
    <th onClick={() => handleSort('quote_status')}>
      Status {sortConfig.key === 'quote_status' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
    </th>
    <th onClick={() => handleSort('partner_name')}>
      Partner Name {sortConfig.key === 'partner_name' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
    </th>
    <th onClick={() => handleSort('quote_desc')}>
      Quote Description {sortConfig.key === 'quote_desc' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
    </th>
    <th onClick={() => handleSort('quote_date')}>
      Quote Date {sortConfig.key === 'quote_date' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
    </th>
    <th onClick={() => handleSort('quote_valid_till')}>
      Valid Till {sortConfig.key === 'quote_valid_till' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
    </th>
    <th onClick={() => handleSort('total_air')}>
      Total Air {sortConfig.key === 'total_air' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
    </th>
    <th onClick={() => handleSort('total_sea')}>
      Total Sea {sortConfig.key === 'total_sea' && (sortConfig.direction === 'asc' ? '↑' : '↓')}
    </th>
  </tr>
</thead>

       <tbody>
  {filteredQuotationsBySearch.map((quotation) => {
    const status = statuses.find((s) => s.value === quotation.quote_status);
    return (
      <tr
        key={quotation.quote_ref}
        onDoubleClick={() => handleRowDoubleClick(quotation.quote_ref)}
      >
        <td>
          <input
            type="checkbox"
            checked={selectedRows.includes(quotation.quote_ref)}
            onChange={() => handleRowSelection(quotation.quote_ref)}
          />
        </td>
        <td>{quotation.quote_ref}</td>
        <td
  style={{
    backgroundColor: status?.color || 'grey',
    color: 'white',
    fontWeight: '500',
    textTransform: 'uppercase',
    padding: '3px 8px', // Još manji padding za niže ćelije
    fontSize: '11px', // Smanjena veličina fonta
    borderRadius: '6px', // Blago zaobljenje
    lineHeight: '1', // Smanjena visina linije
    textAlign: 'center',
    boxShadow: status
      ? '0 1px 3px rgba(0, 0, 0, 0.1)' // Suptilna senka
      : 'none',
    margin: '0', // Bez margine
    cursor: 'pointer',
    transition: 'transform 0.2s ease, box-shadow 0.2s ease',
    height: '24px', // Fiksna visina za još manji izgled
  }}
  onMouseEnter={(e) =>
    (e.currentTarget.style.transform = 'scale(1.03)')
  }
  onMouseLeave={(e) =>
    (e.currentTarget.style.transform = 'scale(1)')
  }
>
  {status?.label || 'N/A'}
</td>

        <td>{quotation.partner_name}</td>
        <td>{quotation.quote_desc}</td>
        <td>{formatDate(quotation.quote_date)}</td>
        <td>{formatDate(quotation.quote_valid_till)}</td>
        <td>{quotation.total_air}</td>
        <td>{quotation.total_sea}</td>
      </tr>
    );
  })}
</tbody>

      </table>
)}
      <div className="pagination">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
        >
          {'<'}
        </button>
        {Array.from({ length: Math.ceil(filteredQuotations.length / quotationsPerPage) }, (_, index) => (
          <button
            key={index + 1}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? 'active' : ''}
            disabled={currentPage === index + 1}
          >
            {index + 1}
          </button>
        ))}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === Math.ceil(filteredQuotations.length / quotationsPerPage)}
        >
          {'>'}
        </button>
      </div>
    </div>
  );
}

export default Quotations;
