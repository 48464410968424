import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

// Import komponenata iz foldera 'components'
import Sidebar from "./components/Sidebar";
import Dashboard from "./components/Dashboard"; 
import Quotations from "./components/Quotations"; 
import Rates from "./components/Rates"; 
import Clients from "./components/Clients"; 
import Settings from "./components/Settings"; 
import Feedback from "./components/Feedback"; 
import Logs from "./components/Logs"; 

import QuotationDetails from "./components/QuotationDetails"; // Import QuotationDetails
import "./App.css";
import AddQuotation from "./components/AddQuatation";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import RateFcl_m from "./components/RatesMenagement/RateFcl_m";
import RateLcl_m from "./components/RatesMenagement/RateLcl_m";
import RateAir_m from "./components/RatesMenagement/RateAir_m";
import RateHandling_m from "./components/RatesMenagement/RateHandling_m";
import Layout from "./Layout";
function App() {

  
  return (
    <Router>
      <div className="app-container">
     
        <ToastContainer />
        <div className="main-content">
          {/* Definisanje ruta */}
          <Routes>
          <Route
          path="/dashboard"
          element={
            <Layout>
              <Dashboard />
            </Layout>
          }
        />
            <Route
    path="/quotations"
    element={
      <Layout>
        <Quotations />
      </Layout>
    }
  />
  <Route
    path="/clients"
    element={
      <Layout>
        <Clients />
      </Layout>
    }
  />
  <Route
    path="/settings"
    element={
      <Layout>
        <Settings />
      </Layout>
    }
  />
  <Route
    path="/feedback"
    element={
      <Layout>
        <Feedback />
      </Layout>
    }
  />
  <Route
    path="/logs"
    element={
      <Layout>
        <Logs />
      </Layout>
    }
  />
  <Route
    path="/addquote"
    element={
      <Layout>
        <AddQuotation />
      </Layout>
    }
  />
  <Route
    path="/fcl-rates"
    element={
      <Layout>
        <RateFcl_m />
      </Layout>
    }
  />
   <Route
    path="/lcl-rates"
    element={
      <Layout>
        <RateLcl_m />
      </Layout>
    }
  />
   <Route
    path="/air-rates"
    element={
      <Layout>
        <RateAir_m />
      </Layout>
    }
  />
    <Route
    path="/handling-rates"
    element={
      <Layout>
        <RateHandling_m />
      </Layout>
    }
  />
  <Route
    path="/quotation-details/:quoteRef"
    element={
      <Layout>
        <QuotationDetails />
      </Layout>
    }
  />
  <Route
    path="/"
    element={
      <Layout>
        <Dashboard />
      </Layout>
    }
  />
  </Routes>
        </div>
      </div>
    </Router>
  );
}

export default App;
