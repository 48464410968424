import React, { useState, useEffect } from 'react';
import { FaSpinner, FaChevronRight, FaChevronDown, FaSave, FaTrash } from 'react-icons/fa';
import { useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import supabase from '../config/supaBaseClient';
import FclRates from './FclRates';
import LclRates from './LclRates';
import AirRates from './AirRates';
import '../css/QuotationDetails.css';
import { v4 as uuidv4 } from 'uuid';
import QuoteHeader from './QuoteHeader';


function QuotationDetails({ isSidebarOpen }) {
  const { quoteRef } = useParams();
  const [quoteMaster, setQuoteMaster] = useState(null);
  const [portPairs, setPortPairs] = useState([]);
  const [expandedRows, setExpandedRows] = useState({});
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);

        const { data: masterData, error: masterError } = await supabase
          .from('quote_master')
          .select('*')
          .eq('quote_ref', quoteRef)
          .single();

        if (masterError) throw new Error(`Error fetching master data: ${masterError.message}`);
        setQuoteMaster(masterData);

        const { data: portPairsData, error: portPairsError } = await supabase
          .from('quote_port_pair')
          .select('*')
          .eq('quote_ref', quoteRef);

        if (portPairsError) throw new Error(`Error fetching port pairs: ${portPairsError.message}`);

        const sanitizedData = portPairsData.map((pair) => ({
          ...pair,
          from_port_name: pair.from_port_name || '',
          to_port_name: pair.to_port_name || '',
          cargo_type: pair.cargo_type || '',
          job_type: pair.job_type || '',
          incoterm: pair.incoterm || '',
          port_notes: pair.port_notes || '',
        }));

        setPortPairs(sanitizedData);
      } catch (error) {
        console.error(`Error in fetchData: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [quoteRef]);

  const toggleRow = (portRef) => {
    setExpandedRows((prev) => ({
      ...prev,
      [portRef]: !prev[portRef],
    }));
  };

  const handleInputChange = (e, index, field) => {
    const newValue = e.target.value;
    setPortPairs((prev) => {
      const updatedPairs = [...prev];
      updatedPairs[index][field] = newValue;
      return updatedPairs;
    });
  };

  const handleSave = async (index) => {
    try {
      const updatedPortPair = portPairs[index];

      if (
        !updatedPortPair.quote_ref ||
        !updatedPortPair.port_ref ||
        !updatedPortPair.from_port ||
        !updatedPortPair.from_port_name ||
        !updatedPortPair.to_port ||
        !updatedPortPair.to_port_name ||
        !updatedPortPair.cargo_type ||
        !updatedPortPair.job_type ||
        !updatedPortPair.incoterm
      ) {
        Swal.fire({
          icon: 'error',
          title: 'Validation Error',
          text: 'Please fill in all required fields for Port Pair.',
        });
        return;
      }

      const portPairDataToSave = {
        ...updatedPortPair,
        company: 'JGLS',
        entity_code: 'SGSIN',
        create_user: 'admin',
        update_user: 'admin',
      };

      const { data: existingPortPair, error: checkError } = await supabase
        .from('quote_port_pair')
        .select('port_ref')
        .eq('company', portPairDataToSave.company)
        .eq('entity_code', portPairDataToSave.entity_code)
        .eq('quote_ref', portPairDataToSave.quote_ref)
        .eq('port_ref', portPairDataToSave.port_ref);

      if (checkError) throw new Error(`Error checking existing port pair: ${checkError.message}`);

      if (existingPortPair && existingPortPair.length > 0) {
        const { error: updateError } = await supabase
          .from('quote_port_pair')
          .update(portPairDataToSave)
          .eq('company', portPairDataToSave.company)
          .eq('entity_code', portPairDataToSave.entity_code)
          .eq('quote_ref', portPairDataToSave.quote_ref)
          .eq('port_ref', portPairDataToSave.port_ref);

        if (updateError) throw new Error(`Error updating port pair: ${updateError.message}`);

        Swal.fire('Success', 'Port pair updated successfully!', 'success');
      } else {
        const { error: insertError } = await supabase
          .from('quote_port_pair')
          .insert([portPairDataToSave]);

        if (insertError) throw new Error(`Error inserting port pair: ${insertError.message}`);

        Swal.fire('Success', 'New port pair added successfully!', 'success');
      }
    } catch (error) {
      console.error('Error saving port pair:', error.message);
      Swal.fire('Error', error.message, 'error');
    }
  };

  const handleDelete = async (portRef, index) => {
    try {
      const { error } = await supabase.from('quote_port_pair').delete().eq('port_ref', portRef);

      if (!error) {
        setPortPairs((prev) => prev.filter((_, i) => i !== index));
        Swal.fire('Success', 'Port pair deleted successfully!', 'success');
      } else {
        throw new Error(error.message);
      }
    } catch (error) {
      console.error('Error deleting port pair:', error.message);
      Swal.fire('Error', error.message, 'error');
    }
  };

  const handleAddPortPair = () => {
    const newPortPair = {
      port_ref: uuidv4(),
      quote_ref: quoteRef,
      from_port: '',
      from_port_name: '',
      to_port: '',
      to_port_name: '',
      cargo_type: '',
      job_type: '',
      incoterm: '',
      port_notes: '',
    };

    setPortPairs((prev) => [...prev, newPortPair]);
  };

  return (
    <div
    className={`quotation-details-container ${
      isSidebarOpen ? "sidebar-open" : "sidebar-closed"
    }`}
  >
      <QuoteHeader quoteMaster={quoteMaster} loading={loading} />

      <div className="quote-master">
  <h3 className='section-ttile'>PORT PAIRS</h3>
 

      <div className="add-port-pair-wrapper">
  <button onClick={handleAddPortPair} className="add-port-pair-btn">
    + Add Port Pair
  </button>
</div>

      {loading ? (
        <div className="loading-container">
          <FaSpinner className="spinner-icon" size={40} spin="true" />
          <p>Loading port pairs...</p>
        </div>
      ) : portPairs.length === 0 ? (
        <p>No Port Pairs found for this Quote Ref.</p>
      ) : (
        <table className="quotation-table">
          <thead>
            <tr>
              <th></th>
              <th>From Port</th>
              <th>From Port Name</th>
              <th>To Port</th>
              <th>To Port Name</th>
              <th>Cargo Type</th>
              <th>Job Type</th>
              <th>Incoterm</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {portPairs.map((pair, index) => (
              <React.Fragment key={pair.port_ref}>
                <tr>
                  <td>
                    <button onClick={() => toggleRow(pair.port_ref)} className="expand-btn">
                      {expandedRows[pair.port_ref] ? <FaChevronDown /> : <FaChevronRight />}
                    </button>
                  </td>
                  <td>
                    <input
                      type="text"
                      value={pair.from_port}
                      onChange={(e) => handleInputChange(e, index, 'from_port')}
                      maxLength={5}
                      placeholder="From Port"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={pair.from_port_name}
                      onChange={(e) => handleInputChange(e, index, 'from_port_name')}
                      placeholder="From Port Name"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={pair.to_port}
                      onChange={(e) => handleInputChange(e, index, 'to_port')}
                      maxLength={5}
                      placeholder="To Port"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={pair.to_port_name}
                      onChange={(e) => handleInputChange(e, index, 'to_port_name')}
                      placeholder="To Port Name"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={pair.cargo_type}
                      onChange={(e) => handleInputChange(e, index, 'cargo_type')}
                      maxLength={3}
                      placeholder="Cargo Type"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={pair.job_type}
                      onChange={(e) => handleInputChange(e, index, 'job_type')}
                      maxLength={1}
                      placeholder="Job Type"
                    />
                  </td>
                  <td>
                    <input
                      type="text"
                      value={pair.incoterm}
                      onChange={(e) => handleInputChange(e, index, 'incoterm')}
                      maxLength={3}
                      placeholder="Incoterm"
                    />
                  </td>
                  <td className="save-delete-wrapper">
                    <button onClick={() => handleSave(index)} className="save-btn">
                      <FaSave />
                    </button>
                    <button onClick={() => handleDelete(pair.port_ref, index)} className="delete-btn">
                      <FaTrash />
                    </button>
                  </td>
                </tr>
                {expandedRows[pair.port_ref] && (
                  <tr>
                    <td colSpan="9">
                      <div className="nested-rates">
                        <FclRates portRef={pair.port_ref} quoteRef={quoteRef} />
                        <LclRates portRef={pair.port_ref} quoteRef={quoteRef} />
                        <AirRates portRef={pair.port_ref} quoteRef={quoteRef} />
                      </div>
                    </td>
                  </tr>
                )}
              </React.Fragment>
            ))}
          </tbody>
        </table>
      )}
    </div>
    </div>
  );
}

export default QuotationDetails;
