import React from 'react';

function Clients() {
  return (
    <div>
      <h2>Clients</h2>
      {/* Sadržaj za Clients */}
    </div>
  );
}

export default Clients;
